<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-card flat class="background_color_transparent">
                    <v-card-title>
                        <h1>
                            {{ $t('eav_entity_type.plural') }}
                        </h1>
                        <v-spacer></v-spacer>
                        <Btn
                            color="secondary"
                            :to="{
                                name: 'eav.type.edit',
                                params: {
                                    slug: 'create'
                                }
                            }">
                            {{ $t('create') }}
                        </Btn>
                        <Btn @click="fetchItems" plain>
                            <v-icon>
                                mdi-refresh
                            </v-icon>
                            {{ $t('refresh') }}
                        </Btn>
                    </v-card-title>
                    <v-card-text>
                        <div class="buttons-block mb-4">

                        </div>
                        <ResizableTable
                            :columns="headers"
                            :rows="items"
                        >
                            <template v-slot:name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <router-link :to="{name: 'eav.entity', params: {slug: item.slug}}">
                                        {{ item.name }}
                                    </router-link>
<!--                                    <v-icon v-if="!item.deleted" @click="deleteItem(item.slug)" small>mdi-delete-outline</v-icon>-->
<!--                                    <router-link :to="{name: 'eav.type.edit', params: {slug: item.slug}}" class="cursor-pointer font_weight_600">-->
<!--                                        <v-icon small>mdi-pencil-outline</v-icon>-->
<!--                                    </router-link>-->
                                </div>
                            </template>
                            <template v-slot:props="{item}">
                                <router-link :to="{name: 'eav.type.property', params: {slug: item.slug}}" class="cursor-pointer font_weight_600">
                                    {{ $t('eav.props') }}
                                </router-link>
                            </template>
                        </ResizableTable>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-pagination v-show="totalCountries > perPage" v-model="page" :total-visible="totalVisiblePag" class="pagination_table" :length="pageCount" :disabled="loading"></v-pagination>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import SettingMenu from "../components/SettingMenu";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "EavEntityTypes",
    components: {
        Btn,
        SettingMenu
    },
    data() {
        return {
            headers: [
                {
                    active: true,
                    label: this.$t('eav.name'),
                    align: "left",
                    prop: "name"
                },
                {
                    active: true,
                    label: this.$t('eav.slug'),
                    align: "left",
                    prop: "slug"
                },
                {
                    active: true,
                    label: this.$t('eav.model'),
                    align: "left",
                    prop: "model"
                },
                {
                    active: true,
                    label: this.$t('eav.primary'),
                    align: "left",
                    prop: "primary"
                },
                // {
                //     active: true,
                //     label: this.$t('eav.props'),
                //     align: "left",
                //     prop: "props"
                // },
            ],
            items: [],
            perPage: 25,
            loading: false,
            page: 1,
            pageCount: 0,
            totalVisiblePag: 7,
            totalCountries: 0,
            sortBy: 'name',
            sortDir: false,
            options: {}
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.fetchItems();
    },
    methods: {
        fetchItems() {
            this.loading = true;
            this.$http.get('admin/eav/type')
                .then(response => {
                    this.items = response.data.data;
                    this.totalCountries = response.data.total;
                })
                .catch(error => {
                    this.$store.dispatch('showError', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        deleteItem(itemSlug) {
            this.$http.delete(`admin/eav/type/${itemSlug}`)
                .then(() => {
                    this.fetchItems();
                })
                .catch(error => {
                    this.$store.dispatch('showError', error);
                });
        },
    }
}
</script>
